import { palette } from './palette';
import createTypography, { TypographyOptions } from "@material-ui/core/styles/createTypography";

export const fontFamily = {
  title: 'Karla, sans-serif',
  body: 'IBM Plex Sans, sans-serif'
}

const customTypography: TypographyOptions = {
  h1: {
    fontFamily: fontFamily.title,
    fontWeight: 600,
    fontSize: '60px',
    lineHeight: 1.2
  },
  h2: {
    fontFamily: fontFamily.title,
    fontWeight: 600,
    fontSize: '40px',
    lineHeight: 1.2
  },
  h3: {
    fontFamily: fontFamily.title,
    fontWeight: 500,
    fontSize: '30px',
    lineHeight: 1.2
  },
  h4: {
    fontFamily: fontFamily.title,
    fontWeight: 500,
    fontSize: '25px',
    lineHeight: 1.2
  },
  h5: {
    fontFamily: fontFamily.title,
    fontWeight: 500,
    fontSize: '18px',
    lineHeight: 1.2
  },
  h6: {
    color: palette.text.primary,
    fontWeight: 500,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '20px'
  },
  subtitle1: {
    fontFamily: fontFamily.title,
    fontWeight:300,
    fontSize: '14px',
    lineHeight: 1.2
  },
  subtitle2: {
    color: palette.text.secondary,
    fontWeight: 400,
    fontSize: '14px',
    letterSpacing: '-0.05px',
    lineHeight: '21px'
  },
  body1: {
    fontFamily: fontFamily.body,
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: 1.2
  },
  body2: {
    fontFamily: fontFamily.body,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: 1.2
  },
  button: {
    fontFamily: fontFamily.title,
    fontWeight: 500,
    fontSize: '15px',
    lineHeight: '26px'
    // lineHeight: 1.2
  },
  caption: {
    color: palette.text.secondary,
    fontSize: '11px',
    letterSpacing: '0.33px',
    lineHeight: '13px'
  },
  overline: {
    color: palette.text.secondary,
    fontSize: '11px',
    fontWeight: 500,
    letterSpacing: '0.33px',
    lineHeight: '13px',
    textTransform: 'uppercase'
  }
};

export const typography = createTypography(palette, customTypography)
