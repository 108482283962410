import React, {useState} from 'react'
// import { getCookie } from "../../../shared/API"
import ReactPlayer from 'react-player'
import Waveform from "../../../constants/soundWave/Waveform";
import PlayList from "../../../constants/soundWave/PlayList";

const tracks = [
    {
      id: 0,
      title: "Brahms: St Anthony Chorale - Theme, Two Pianos Op.56b",
      url:
        "https://www.mfiles.co.uk/mp3-downloads/brahms-st-anthony-chorale-theme-two-pianos.mp3"
    },
    {
      id: 1,
      title: "Franz Schubert's Ständchen - Voice (Clarinet) & Piano",
      url:
        "https://www.mfiles.co.uk/mp3-downloads/franz-schubert-standchen-serenade.mp3"
    }
  ];

const VideoPlayer = () => {
    const [selectedTrack, setSelectedTrack] = useState(tracks[0]);

    return (
        <>
            <h1>React player</h1>
            <video controls width="250">

                <source src="https://cochl-video-upload-test.s3.ap-northeast-2.amazonaws.com/test/hls/upload/9x6rqa7ql1kybv7i.m3u8" 
                         type="application/x-mpegURL"/>
            </video>
            <div className="App">
                <Waveform url={selectedTrack.url} />
                <PlayList
                    tracks={tracks}
                    selectedTrack={selectedTrack}
                    setSelectedTrack={setSelectedTrack}
                />

            </div>



        </>
    )
}

export default VideoPlayer;