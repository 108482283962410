import styled from "styled-components"
import { globalStyles } from "../../constants"
import MSLogo from "../../constants/images/logo.png"
import Button from '@material-ui/core/Button';
import { MyTheme } from "../../theme"
import { Link } from 'react-router-dom';

const TopBar = styled.div`
  width: 100%;
  height: ${globalStyles.height.MainHeader};
`
const Innerwrap = styled.div`
  width: ${globalStyles.innerWrap.width};
  margin: 0 auto;
  padding-top: 31px;
  img {
    float: left;
  }
  overflow: hidden;
`
const Login = styled.div`
  float: right;
`
const ButtonDefault = styled(Button)`
  .MuiButton-label {
    font-family: ${MyTheme.fontFamily.title};
    line-height: 20px;
    font-size: 12px;
    font-weight: 600;
  }
`

const Main = (props : Props) => {
  const { children, UserAuth } = props

  return (
    <>
    <TopBar>
      <Innerwrap>
        <img src={MSLogo} alt=""/>
        <Login>
          {UserAuth == "true"
          ? <ButtonDefault color="primary" style={{color:'white'}}>
              Log out
            </ButtonDefault>
          : 
            <Link to="/sign-in">
              <ButtonDefault color="primary" style={{color:'white'}}>
                Log in
              </ButtonDefault>
            </Link>
          }

        </Login>
      </Innerwrap>
    </TopBar>
    {children}
    </>
  )
}

type Props = {
  children : Node,
  UserAuth : any
}

export default Main
