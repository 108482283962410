import React from 'react';
import ReactDOM from 'react-dom';
import './constants/fonts.css'
import './index.css';
import Root from "./client/Root";
import {Provider} from "react-redux";
import store from "./modules/store"

ReactDOM.render(
  <Provider store={store}>
    <Root/>
  </Provider>,
  document.getElementById('root')
);

