import React from 'react'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'
import Routers from '../shared/Routers'

const browserHistory = createBrowserHistory()
// Init google analytics
// useGoogleAnalytics.init()

const Root: React.FC = () => {
  
  return (
    <Router history={browserHistory}>
      <Routers/>
    </Router>
  )
}

export default Root