//import
import React, {useEffect, useState} from 'react'
import axios from 'axios'
import { Redirect, useHistory } from 'react-router-dom'
//styles
import { Box, Button, Link, TextField, Typography, makeStyles, withStyles } from "@material-ui/core"
import VideoCallIcon from '@material-ui/icons/VideoCall'
import { DeleteForever, Edit } from '@material-ui/icons'
import styled from "styled-components"
import theme, { MyTheme } from "../../../theme"
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { DashboardModal } from "@uppy/react"
import { getVideoData } from "../../../shared/API";
import Basic from "../../../constants/images/projects_basic.png"

const Project= styled.a`
    display: block;
    width : 230px;
    height: 230px;
    background-color: #1A1C24;
    ul {
        display: flex;
        flex-direction: row-reverse;
        padding: 5px 10px 10px 0;  
    }
    border: 1px solid transparent;
    border-radius: 5px;
`
const Thumnail= styled.div`
    height: 140px;
    width: 100%;
`
const Contetns= styled.div`
    /* height: 140px; */
    width: 100%;
`
const Title= styled.div`
    padding-left: 15px;
    padding-top: 10px;
    ${MyTheme.typography.body1};
    font-weight: 600;
    line-height: 14px;
`
const ModifiedDate= styled.div`
    padding-left: 15px;
    padding-top: 10px;
    ${MyTheme.typography.body2};
    font-size: 10px;
    font-weight: 400;
    line-height: 14px;
`
const Modify= styled.li`
    color: ${MyTheme.palette.white};
`
const Delete= styled.li`
    color: ${MyTheme.palette.white};
`


const Projects = (props) => {
    const {name, date, id} = props

    let history = useHistory();
    const [uploadOpen, setuploadOpen] = useState(false)
    const handleUpLoad = () => {
        setuploadOpen(true);
        if(uploadOpen) {
            localStorage.setItem('videoId', id)
            history.push('/Separate');
        }
    }
    
    return(
        <>
            <Project className="projectFile" onClick={handleUpLoad}>
                <Thumnail>
                    <img src={Basic} alt=""/>
                </Thumnail>
                <Contetns>
                    <Title>{name}</Title>
                    <ModifiedDate>{date}</ModifiedDate>
                    <ul>
                        <Edit fontSize="small"/>
                        <DeleteForever fontSize="small" style={{marginRight: '10px'}}/>
                    </ul>
                    <p>{id}</p>
                </Contetns>
            </Project>
        </>
    )
}


const AddedProject = () => {
    
    const [userList, setuserList] = useState();
    const myVideoList = async() => {
        try {
            const myVideoList = await getVideoData();
            const a = myVideoList.data.data;
            if(userList == null) {
                setuserList(a)
            }
        } catch {
            // const userList
        }
    }
    myVideoList();
    console.log(userList);
    
    return (
            <>
            {
                userList
                ? userList.map((item) => <Projects name={item.name} date={item.updated_at} id={item.id}/>)
                : <div></div>                
            }
            </>
        )
}

export default AddedProject;
