import { palette } from './palette';
import { typography } from './typography';

export const MuiButton = {
  root: {
    letterSpacing: '0.15px',
    fontWeight: 600,
  },
  textPrimary: {
    color: '#2196F3'
  },
  contained: {
    boxShadow:
      '0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)',
    backgroundColor: '#FFFFFF'
  }
};

export const MuiDialog = {
  paper: {
    margin: '16px',
  }
}

export const MuiDialogContent = {
  root: {
    '&::-webkit-scrollbar': {
      width: '7px',
      height: '7px'
    }
  }
}

export const MuiDialogContentText = {
  root: {
    marginTop: '12px',
    marginBottom: '0'
  }
};

export const MuiDialogTitle = {
  root: {
    marginTop: '6px',
    fontSize: '22px',
    fontWeight: 700
  }
};

export const MuiDivider = {
  root: {
    height: '1px',
    backgroundColor: 'rgba(255, 255, 255, 0.3)',
  }
};

export const MuiIconButton = {
  root: {
    color: palette.icon,
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.03)'
    }
  }
};

export const MuiPaper = {
  elevation1: {
    boxShadow: '0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15)'
  }
};

export const MuiSelect = {
  icon: {
    color: '#fff'
  }
};

export const MuiTableCell = {
  root: {
    ...typography.body1,
    borderBottom: '1px solid rgba(252, 252, 252, 0.2)'
  }
};

export const MuiTableHead = {
  root: {
  }
};

export const MuiTableRow = {
  root: {
    border: 'unset',
    '&$selected': {
      backgroundColor: palette.background.default
    },
    '&$hover': {
      '&:hover': {
        backgroundColor: palette.background.default
      }
    }
  }
};

export const MuiTypography = {
  gutterBottom: {
    marginBottom: 8
  }
};
