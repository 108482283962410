import React from 'react'
import { Route } from 'react-router-dom'
import 'react-toastify/dist/ReactToastify.css';

const RouteWithLayoutEditor = (props : Props) => {
  const {layout : Layout, component : Component, UserAuth } = props


  return (
    <Route
      render={(matchProps) => (
        <Layout UserAuth={UserAuth}>
          <Component/>
        </Layout>
      )}
    />
  )
}

type Props = {
  layout: any,
  component: any,
  UserAuth :any
}
// RouteWithLayoutEditor.propTypes = {
//   component: PropTypes.any.isRequired,
//   layout: PropTypes.any.isRequired,
//   path: PropTypes.string,
//   user: PropTypes.any,
//   logout: PropTypes.func,
//   title: PropTypes.string,
// //   pageRouteTabItems: PropTypes.array,
// //   currPageRouteTabIdx: PropTypes.number
// }

export default RouteWithLayoutEditor
