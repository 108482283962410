//styles
import styled from 'styled-components';
import { MyTheme } from '../../../theme';
import { tableIcons } from '../../../constants'
//UploadVideo
import React, {useState} from 'react'
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import { Dashboard } from "@uppy/react"
import '../../../constants/customStyles/UppyDashboard.css'
import Modal from '@material-ui/core/Modal';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';

//Modal
// function rand() {
//   return Math.round(Math.random() * 20) - 10;
// }

function getModalStyle() {

  const height = 172;
  const width = 641;

  return {
    top: `calc(50% - (${height}px/2))`,
    left: `calc(50% - (${width}px/2))`,
    // transform: `translate(-${top}%, -${left}%)`
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    outline: 'none !important',
    '&:focus': {
      outline: 'none !important'
    }
  }
}));

//Styles
const Close = styled(CloseIcon)`
  color: white;
  position: absolute;
  z-index: 1000;
  right: 10px;
  top: 10px;
  cursor: pointer;
  outline: none;
  &:focus {
    outline: none;
  }
  &:hover {
    outline: none;
  }
`

const AddProject = styled.a`
    display: block;
    position: relative;
    width: 230px;
    height: 230px;
    text-align: center;
    border: 1px solid transparent;
    border-radius: 5px;
    background-color: ${MyTheme.otherPalette.topArea};
    cursor: pointer;
`
const Icon = styled(tableIcons.ControlPoint)`
    color: ${MyTheme.palette.secondary.light};
    position: absolute;
    top: calc(50% - 1em);
    left: calc(50% - 0.5em);
`

const Title = styled.span`
    display: block;
    width: 100%;
    position: absolute;
    top: calc(50% + 1em);
    left: 0;
    text-align: center;
    ${MyTheme.typography.body2};
    color: ${MyTheme.palette.secondary.light};
`
    // new uppy
    const getToken = localStorage.getItem('token');
    const uppy = new Uppy({
      autoProceed: true, 
      closeAfterFinish: true, 
      showProgressDetails: true, 
      maxNumberOfFiles: 1,
      minNumberOfFiles: 1,
      allowedFileTypes: ['video/*']
    });
    uppy.use(Tus, { headers: {
      'Authorization': `Bearer ${getToken}`
    }, endpoint: 'https://file.music-swapper.beta.cochl.ai/v1/files/', chunkSize: 5242880, overridePatchMethod: false});
   
const Newproject = () => {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const ModalBody = (
    <div style={modalStyle} className={classes.paper}>
      <Close onClick={handleClose}/>
      <Dashboard 
      uppy={uppy}
      theme='dark' 
      width={641} 
      height={179}
      />
    </div>
    
  );

  //async랑 await를 사용하기
  return(
    <div className="projectFile">
      <AddProject onClick={handleOpen}>
          <Icon/>
          <Title>
              New Content
          </Title>
      </AddProject>

      <Modal
        open={open}
        onClose={handleClose}
      >
        {ModalBody}
      </Modal>
    </div>    
  );
};

export default Newproject;
