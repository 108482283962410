//UploadVideo
import React, {useState} from 'react'
import { Box, Button, Link, TextField, Typography, makeStyles, withStyles } from "@material-ui/core"
import styled from "styled-components"
import Uppy from "@uppy/core";
import Tus from "@uppy/tus";
import '@uppy/core/dist/style.css'
import '@uppy/dashboard/dist/style.css'
import { DashboardModal } from "@uppy/react"

const ModalBox= styled.div`
    margin-top: 80px;
    width: 1200px;
    background-color: #d8a0a0;
`

// new uppy
const UploadVideo = () => {
    // new uppy
    const uppy = new Uppy({
      restrictions : {
        id: 'uppy',
        maxFileSize: 100000000,
        maxNumberOfFiles: 1,
        allowedFileTypes: ['image/*', 'video/*']
      }
    }
    )
    uppy.use(Tus, { headers: {
      'Authorization': `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFzY2hvQGNvY2hsLmFpIiwidXNlciI6dHJ1ZSwiZXhwIjoxNjE3MDk1MzAwLCJpYXQiOjE2MTcwMDg5MDAsImlzcyI6Imh0dHBzOi8vbXVzaWMtc3dhcHBlci5iZXRhLmNvY2hsLmFpLyJ9.zI8EC9fkWuckteC9iNePJc8tsf1iIpXcvNCg9nU4EK8`
    }, endpoint: 'http://file.music-swapper.beta.cochl.ai/v1/files/', chunkSize: '35MB'})

    // const [selectedCategoryIndex, setSelectedCategoryIndex] = useState(0)	
    const [modalOpen,setmodalOpen] = useState(false)
    const handleOpen = () => {
      setmodalOpen(true);
      // alert('Hello');
    }
    return(
        <ModalBox>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Primary
          </Button>
          <DashboardModal uppy={uppy} open={modalOpen}/>
        </ModalBox>
    );
}

export default UploadVideo;