import styled from 'styled-components';
import { tableIcons } from '../../../constants'

// components-styles 
const SelectArea= styled.div`
    margin-top: 50px;
    position: relative;
    display: inline-block;
    width: auto;
    select {
        padding-right: 20px;
        appearance: none;
        -webkit-appearance: none;
        -moz-appearance: none;
    }
    select:focus {
        border-color: transparent;
    }
`
const Icon= styled(tableIcons.ArrowDown)`
    position: absolute;
    top: -5px;
    left: calc(100% - 20px);
`
const Select= {
    backgroundColor: 'transparent',
    borderColor: 'transparent',
    color: '#fff',
    borderWidth: '0px',
    borderStyle: 'none'
}

const Sorting = () => {
    return (
        <SelectArea>
            <select name="" id="" style={Select}>
                <option value="">Date modified</option>
                <option value="">Alphabet</option>
            </select>
            <Icon/>
        </SelectArea> 
    );
}

export default Sorting;