import React, { forwardRef } from "react";
//icons
import ControlPointIcon from '@material-ui/icons/ControlPoint';
import {KeyboardArrowDown} from '@material-ui/icons';


export const API_BASE_URL= 'https://music-swapper.beta.cochl.ai/'
export const IMG_URL_PREFIX = 'https://cochl-images.s3.ap-northeast-2.amazonaws.com/'
export {default as globalStyles } from './globalStyles'
export {default as ImageUrls } from './ImageUrls'

//icons
export const tableIcons = {
    ControlPoint: forwardRef((props, ref) => <ControlPointIcon {...props as any} ref={ref} />),
    ArrowDown: forwardRef((props, ref) => <KeyboardArrowDown {...props as any} ref={ref} />)
}