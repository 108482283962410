import { configureStore, createSlice } from "@reduxjs/toolkit"


const userAuth = createSlice({
    name: "userAuthReducer",
    initialState: "false",
    reducers: {
        signIn: (state, action) => state = action.payload
    }
})

export const { signIn } = userAuth.actions;
const store = configureStore({reducer: userAuth.reducer});
export default store;