import React from 'react'
import PropTypes from 'prop-types'
import styled from "styled-components";
import {MyTheme} from "../../theme"
import {globalStyles} from "../../constants"
import {Logo, Nav, UserInfo}  from './components'

// styled-components
const HeaderArea = styled.div`
    position: fixed;
    width: 100%;
    height: ${globalStyles.height.HeaderArea};
    background-color: ${MyTheme.otherPalette.header};
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
`
const HLogo = styled(Logo)`
    display: block;
    width: 25%;
`
const HNav = styled(Nav)`
    display: block;
    width: 50%;
`
const HUserInfo = styled(UserInfo)`
    display: block;
    width: 25%;
`

const Editor = props => {
  const { children } = props
  return (
    <>
      <HeaderArea>
          <HLogo/>
          <HNav/>
          <HUserInfo/>
      </HeaderArea>
      {children}
    </>
  )
}

Editor.propTypes = {
  children: PropTypes.node
}

export default Editor
