import {VideoPlayer} from './components'

const Separate = () => {
    return(
        <>
        <h1 style={{ paddingTop: '100px'}}>Hello, This is a mic check</h1>
            <VideoPlayer />
        </>
    );
}

export default Separate;