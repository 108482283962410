import {fontFamily} from '../theme/typography'
// include style
const globalStyles= {
    height : {
        HeaderArea: '52px',
        TopArea: '230px',
        MainHeader: '85px'
    },
    innerWrap : {
        width: '1020px',
        margin: '0 auto',
        position: 'relative'
    },
    otherFonts: {
        userInfo: {
            fontFamily: fontFamily.title,
            fontWeight: 500,
            fontSize: '19px',
            lineHeight: '31px'
          },
          userTitle: {
            fontFamily: fontFamily.title,
            fontWeight: 600,
            fontSize: '25px'
          }
    }
}

export default globalStyles;
