import {UserName, Sorting, NewProject, AddedProject, ProjectArea}  from './components'
import React, { useEffect } from "react";

//styles
import styled from 'styled-components'
import {MyTheme} from '../../theme'
import {globalStyles} from '../../constants'

const InnerWrapArea= styled.div`
${globalStyles.innerWrap};
`
const WrapArea= styled.div`
    padding-top: ${globalStyles.height.HeaderArea};
`
const TopArea= styled.div`
    height: ${globalStyles.height.TopArea};
    background-color: ${MyTheme.otherPalette.topArea};
    margin-bottom: 60px;
`
const Projects = () => {

    const UserInfo = localStorage.getItem('userEmail')
    const UserId = localStorage.getItem('userId')
    return <>
        <WrapArea>
            <TopArea>
                <InnerWrapArea>
                    <UserName UserInfo={UserInfo}/>
                    <Sorting/>
                </InnerWrapArea>
            </TopArea>
            <ProjectArea UserId={UserId}/>
        </WrapArea>
    </>
}


export default Projects;