import React, { useEffect, useState } from "react";
import { Redirect, useHistory } from 'react-router-dom'
import { InputCont } from "./InputCont";
import styled from "styled-components"
import { getToken, getUserInfo } from "../../../shared/API";
import Button from '@material-ui/core/Button';
import { connect } from "react-redux";
import { signIn } from "../../../modules/store";

const InnerWrap = styled.div`
  padding-top: 40px;
  width: 400px;
  margin: 0 auto;
  ul {
    padding-bottom: 30px;
  }
  li {
    display: block;
  }
  li:last-child {
    padding-top: 20px;
  }
`

// need to check on server
const SignInPage= ({setAuth} : Props)=> {

  let history = useHistory();

  const handleSignIn = async (event: React.FormEvent)=> {
    event.preventDefault()
    try{
      const response = await getToken(FormState.values)
      if (response.status == 200 || response.status == 204){
          localStorage.setItem('token', response.data.token)
          const Token = localStorage.getItem('token') || ""
          console.log('success')
      }
      const userInfo = await getUserInfo();
      console.log(userInfo);
      localStorage.setItem('userId', userInfo.data.data.id);
      localStorage.setItem('userEmail', userInfo.data.data.email);
      //dispatch to store state.
      setAuth("true")
      history.push('/Projects')
    } catch(e){
        alert('Email or pasword is incorrenct!');
    } finally{
        console.log('sign-in is working!') 
          
    }
  }
  
  type typeFormState= {
    values: {
      email: string,
      password: string
    }
  }
  const [FormState, setFormState]= useState<typeFormState>({
    values: {
      email: '',
      password: ''
    }
  })

  const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>)=> {
    event.persist()
    setFormState( FormState=> ({
      values:{
        ...FormState.values,
        [event.target.name]: event.target.value
      }
    }))
  }

  // useEffect(()=> {
  //   console.log(FormState);
  // },[FormState.values])

  return(
    <InnerWrap>
      <form onSubmit={handleSignIn}>
      <ul>
        <li>
          <InputCont label="Email" name="email" handleChange={handleChange}/>
        </li>
        <li>
          <InputCont label="Password" name="password" handleChange={handleChange}/>
        </li>
      </ul>
      <Button variant="contained" color="primary" type="submit">
        Submit
      </Button>
      </form>
    </InnerWrap>
  )
}

type Props = {
  setAuth: any
}
export const UserAuthDispatchToProps = (dispatch :any) => {
  return {
    setAuth : (auth :any) => dispatch(signIn(auth))
  }
}

export default connect(null, UserAuthDispatchToProps)(SignInPage);
