import React from "react"
import { Redirect, Switch } from 'react-router-dom'
import { SignIn, Main, Projects, Separate } from '../pages'
import RouteWithLayout from './RouteWithLayout'
import { Minimal as MinimalLayout, Main as MainLayout, Editor as EditorLayout } from '../layout'
import { connect } from "react-redux"

const Routers = ({UserAuth})=> {

  return (
    <>
      <Switch>
        <Redirect
          exact
          from="/"
          to="/main"
        />
        <RouteWithLayout
          component={SignIn}
          path="/sign-in"
          layout={MinimalLayout}
          exact
        />
        <RouteWithLayout
          component={Main}
          path="/main"
          layout={MainLayout}
          UserAuth={UserAuth}
          exact
        />
        <RouteWithLayout
          component={Projects}
          path="/Projects"
          layout={EditorLayout}
          exact
        />
        <RouteWithLayout
          component={Separate}
          path="/Separate"
          layout={EditorLayout}
          exact
        />
      </Switch>
    </>
  )
}

export const UserAuthStateToProps = (state) => {
  return {UserAuth: state}
}
export default connect(UserAuthStateToProps)(Routers);
