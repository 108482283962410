import axios from 'axios'
import { API_BASE_URL } from "../constants";


export interface SignInForm {
  email: string,
  password: string
}

axios.defaults.baseURL = API_BASE_URL

export const getToken = ({ email, password }: SignInForm) => {
  return axios.post('/v1/auth/login',
    {
      email: email,
      password: password
    }
  )
}

export const getUserInfo = () => {
  return axios.get('/v1/user', {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}

export const getVideoData = async() => {
  const userId = localStorage.getItem('userId');
  return axios.get(`/v1/user/${userId}/upload`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}

export const getCookie = async() => {
  const videoId = localStorage.getItem('videoId')
  return axios.get(`/v1/upload/${videoId}/sign`, {
    headers: {
      'Authorization': 'Bearer ' + localStorage.getItem('token')
    }
  })
}


