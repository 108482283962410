import { Link } from 'react-router-dom';

// styles
import styled from 'styled-components';
import { MyTheme } from '../../../theme';
import {globalStyles} from '../../../constants'

//UserInfo styles
const UserArea= styled.ul`
    display: flex;
    flex-direction: row-reverse;
`
const UserLink= styled(Link)`
    display:inline-block;
    margin: 10px 40px 0 15px;
    width: 30px;
    height: 30px;
    vertical-align: middle;
    border: 1px solid transparent;
    border-radius: 50%;
    background-color: ${MyTheme.otherPalette.user};
    text-align: center;
`
const FirstLetter= styled.span`
    vertical-align: middle;
    ${globalStyles.otherFonts.userTitle as any};
    color: ${MyTheme.palette.white};
`
const UpgradeLink= styled(Link)`
    display:inline-block;
    margin: 13px 0 0 0;
    width: 93px;
    height: 26px;
    border: 1px solid transparent;
    border-radius: 10px;
    text-align: center;
    background-color: ${MyTheme.palette.primary.light};
    vertical-align: middle;
    color: ${MyTheme.palette.white};
`
const Upgrade= styled.span`
    ${MyTheme.typography.button as any};
    vertical-align: middle;
`
// components
const UserInfo = () => (
    <UserArea>
        <li>
            <UserLink to="#">
                <FirstLetter>U</FirstLetter>
            </UserLink>
        </li>
        <li>
            <UpgradeLink to="#">
                <Upgrade>Upgrade</Upgrade>
            </UpgradeLink>
        </li>
    </UserArea>
        
);

export default UserInfo;