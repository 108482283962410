import { Link } from 'react-router-dom';

// styles
import styled from 'styled-components';
import {MyTheme} from '../../../theme';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';

// Nav style
const NavArea = styled.ul`
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
`
const List = styled.li`
    display: inline-block;
    line-height: 52px;
    vertical-align: middle;
    padding-left: 10px;
    &:first-child {
        padding-left: 0;
    }
`
const NList = styled(Link)`
    display: inline-block;
    padding-right: 10px;
    ${MyTheme.typography.body2 as any};
    vertical-align: middle;
    color: #5B5D66;
`
const NChevron = styled(ChevronRightIcon)`
    vertical-align: middle;
    color: #5B5D66;
`
// components
interface nav {
    page : string
}

function Item(props:nav) {
    const {page} = props;

    return (
        <List>
            <NList to={`/${props.page}`}>{page}</NList>
            {
                page !== "Export" && <NChevron/>
            }
        </List>
    );
}

function ItemCurrent(props:nav) {
    const {page} = props;

    return (
        <List>
            <NList to={`/${props.page}`} style={{color:'white'}}>{page}</NList>
            {
                page !== "Export" && <NChevron style={{color:'white'}}/>
            }
        </List>
    );
}

const Nav = () => {
    const currentLocation = window.location.pathname.slice(1);
    console.log(typeof currentLocation);
    const menu = ['Projects', 'Upload', 'Separate', 'AddMusic', 'Export'];
    console.log(menu);
    return(
        <NavArea>
            {
                menu.map((item) => 
                item === currentLocation
                ? <ItemCurrent page={`${item}`}/>
                : <Item page={`${item}`}/>
                )
            }
        </NavArea>
    );
};

export default Nav;