// about styles
import styled from 'styled-components';
import MSLogo from '../../../constants/images/logo.png'

//로고 이미지 styles
const LogoArea = styled.h1`
    padding: 0 40px;
`
const LogoLink = styled.a`
    display: inline-block;
    vertical-align: middle;
`
const IMG = styled.img`
    vertical-align: middle;
`
// component
const Logo = () => (
    <LogoArea>
        <LogoLink href="/">
            <IMG src={MSLogo} alt=""/>
        </LogoLink>
    </LogoArea>
);

export default Logo;