// styles
import styled from 'styled-components'
import {MyTheme} from '../../../theme'
import {globalStyles} from '../../../constants'

//UserName styles
const Hello= styled.h2`
    padding-top: calc(${globalStyles.height.TopArea}/2 - 20px);
    ${MyTheme.typography.h2 as any};
`
const UserName = (props : Props) => {
    const {UserInfo} = props
    return (
        <Hello>{`Hello, ${UserInfo}`}</Hello>
    );
}

type Props = {
    UserInfo : string
}

export default UserName;