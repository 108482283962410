import {UserName, Sorting, NewProject, AddedProject}  from './index'
import React, { useEffect } from "react";
//styles
import styled from 'styled-components'
import { globalStyles } from '../../../constants'
import { MyTheme } from '../../../theme'

const InnerWrapArea= styled.div`
${globalStyles.innerWrap};
overflow: hidden;
.projectFile {
    float: left;
    margin: 0 30px 30px 0;
    cursor: pointer;
}
.projectFile:hover > a {
    border: 1px solid ${MyTheme.palette.primary.main};
}
a.projectFile:hover {
    border: 1px solid ${MyTheme.palette.primary.main};
}
.projectFile:nth-child(4n) {
    margin-right: 0;
}
`

const ProjectArea = (props) => {
    const {UserId} = props
    return(
        <InnerWrapArea>
            <NewProject/>
            <AddedProject Userid={UserId}/>
        </InnerWrapArea>
    );
}

export default ProjectArea;