import { createMuiTheme } from '@material-ui/core';
import { otherPalette, palette } from './palette';
import { typography, fontFamily } from './typography';
import * as overrides from './overrides';
import { ThemeOptions } from "@material-ui/core/styles/createMuiTheme";
//myTheme

export const MyTheme= {
  otherPalette,palette,typography,fontFamily
}

const customTheme: ThemeOptions = {
  palette,
  typography,
  overrides,
  zIndex: {
    appBar: 1200,
    drawer: 1100
  }
}

const theme = createMuiTheme(customTheme);

export default theme;
