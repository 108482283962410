import styled from "styled-components"

const Minimal = (props : Props) => {
  const { children } = props

  return (
    <>
    {children}
    </>
  )
}

type Props = {
  children : Node
}

export default Minimal
