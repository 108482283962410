import { colors } from '@material-ui/core'
import createPalette, { PaletteOptions } from "@material-ui/core/styles/createPalette";

const white = '#F8F9FA';
const black = '#13141A';
export const paper = '#11161C';
const hover = '#283444';
const selected = '#283444';
export const dialog = '#11161C';
export const block = '#242536';
const purple = '#503FDD';
export const topBar = '#232E3C'
export const tabBar = '#1A1D29'
export const sideBar = '#11161C'
const toolTip = 'rgba(63, 81, 181, 0.9)'
const disabled = '#979797'
export const link = '#289CE1'

//new
export const otherPalette = {
  user : '#E7A668',
  header : '#31343E',
  topArea : '#1A1C24'
}

const customPalette: PaletteOptions = {
  black: black,
  white: white,
  neutral: '#444444',
  type: 'dark',
  primary: {
    contrastText: white,
    dark: '#4B68FF',
    main: '#7048E8',
    light: '#8687FF'
  },
  secondary: {
    contrastText: white,
    dark: '#2C2F3C',
    main: '#A4A5AA',
    light: '#DDE2E5'
  },
  success: {
    contrastText: white,
    dark: colors.green[900],
    main: colors.green[600],
    light: colors.green[400]
  },
  info: {
    contrastText: white,
    main: toolTip,
    light: colors.blue[400]
  },
  warning: {
    contrastText: white,
    dark: colors.orange[900],
    main: colors.orange[600],
    light: colors.orange[400]
  },
  error: {
    contrastText: white,
    dark: colors.red[900],
    main: colors.red[600],
    light: colors.red[400]
  },
  text: {
    primary: white,
    secondary: white,
    disabled: disabled,
    hint: disabled, // ex. days in date range picker calendar, disabled dates in date picker calendar
    link: link
  },
  background: {
    default: block,
    paper: paper,
  },
  action: {
    selected: selected,
    hover: hover,
    disabled: '#565656'
  },
  icon: white,
  divider: colors.grey[200],
};

export const palette = createPalette(customPalette);
