import React from "react";
import TextField from '@material-ui/core/TextField';
import styled from "styled-components"
import MyTheme from "../../../theme"
// import { ImageUrls }from '../../../constants'


export const StyledInput = styled(TextField)`
  .MuiFormLabel-root {
    color: 1px solid rgba(255, 255, 255, 0.42);
  }
  .MuiFormLabel-root.Mui-focused {
    color: ${MyTheme.palette.primary.light};
  }
  .MuiInput-underline:before {
    border-bottom: 1px solid rgba(255, 255, 255, 0.42);
  }
  .MuiInput-underline:after {
    border-bottom: 2px solid ${MyTheme.palette.primary.light};
  }
  .MuiInput-underline:hover:not(.Mui-disabled):before {
    border-bottom: 2px solid ${MyTheme.palette.primary.light};
  }
`


export const InputCont = (props: Props) => {
  const { label, name, handleChange } = props

  return (
          <StyledInput
            label={label}
            name={name}
            onChange={handleChange}
            fullWidth
          />
  )
}

type Props = {
  label: string,
  name: string,
  handleChange: any
}
