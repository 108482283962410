import { SignInPage } from './components'
import styled from "styled-components"
import { Typography } from "@material-ui/core"
import LogoCircle from "../../constants/images/logo_circle.png"

const Wrap = styled.div`
  width: 1500px;
  margin: 0 auto;
  padding-top: 120px;
  text-align: center;
`
const InnerWrap = styled.div`
  padding-top: 40px;
  width: 400px;
  margin: 0 auto;
  li {
    display: block;
  }
  li:last-child {
    padding-top: 20px;
  }
`
const Title = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  img {
    width: 101px;
    padding-right: 30px;
  }
`
const SignIn =() => {
  return(
      <Wrap>
        <Title>
          <span>
            <img src={LogoCircle} alt=""/>
          </span>
          <Typography variant="h2">Music Swapper</Typography>
        </Title>
        
        <SignInPage/>
        
      </Wrap>
  )
}

export default SignIn
